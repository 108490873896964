import CryptoData from './components/CryptoData';

function App() {
  return (
    <div className="App">
      <CryptoData />
    </div>
  );
}

export default App;
